<template>
    <div class="top-nav">
       <h5>
          <i class="fa fa-angle-left icon-back" @click="$router.push('home')"></i>
       </h5>
       <img src="../assets/png/logo.png" class="logo-login" alt="">
       <div class="pl-5 ml-5">
          <img src="../assets/png/en-4c6eba8e.png" class="lang" alt="">
          <span class="text-white lang1">EN</span>
       </div>
    </div>
    <div data-v-47f4cc84="" class="login__container-heading">
       <h1 data-v-47f4cc84="" class="login__container-heading__title">Log in</h1>
       <div data-v-47f4cc84="" class="login__container-heading__subTitle">
          <div data-v-47f4cc84="">Please log in with your phone number or email</div>
          <div data-v-47f4cc84="">If you forget your password, please contact customer service</div>
       </div>
    </div>
    <div class="login__container">
       <div data-v-47f4cc84="" class="login_container-tab">
       <div data-v-47f4cc84="" class="tab" @click="$router.push('login')">
          <svg data-v-47f4cc84="" class="svg-icon icon-phone">
             <use xlink:href="#icon-phone"></use>
          </svg>
          <div data-v-47f4cc84="">Log in with phone</div>
       </div>
       <div data-v-47f4cc84="" class="tab active" @click="$router.push('email')">
          <div data-v-47f4cc84="">
             <svg data-v-47f4cc84="" class="svg-icon icon-email">
                <use xlink:href="#icon-email"></use>
             </svg>
             <svg data-v-47f4cc84="" class="svg-icon icon-user" style="display: none;">
                <use xlink:href="#icon-user"></use>
             </svg>
          </div>
          <div data-v-47f4cc84="">UID Login</div>
       </div>
       </div>
    </div>
    <div class="container-fluid" style="margin-top: -40px;">
       <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
             <form>
                <label for="username" style="margin-bottom: -50px;">
                    <svg data-v-4499df08="" class="svg-icon icon-email emailinput__container-label__icon emailinput__container-label__icon text-danger" width="30px">
                        <use xlink:href="#icon-email"></use>
                    </svg><span>&nbsp;UserId</span>
                </label>
                <br>
                <input type="text" v-model="user.u_id" class="login-password" maxlength="12" required minlength="6" placeholder="TCG000001">
                
                <label for="username" style="margin-bottom: -50px; margin-top: -30px">
                   <svg data-v-ea5b66c8="" class="svg-icon icon-editPswIcon" width="30px">
                      <use xlink:href="#icon-editPswIcon"></use>
                   </svg><span>&nbsp;Password</span>
                </label>
                <!-- <input type="password" v-model="user.password" minlength="6" maxlength="22" required class="login-password" placeholder="Password"> -->
                <div data-v-ea5b66c8="" class="passwordInput__container-input "><input data-v-ea5b66c8="" :type="inputType" placeholder="Set password" maxlength="15" v-model="user.password" autocomplete="new-password" class="login-password">
                  <img data-v-ea5b66c8="" src="../assets/png/eyeInvisible-821d9d16.png" @click="toggleInputType" class="eye" v-if="inputType==='password'">
                  <img data-v-ea5b66c8="" src="../assets/png/eyeVisible-09720f5f.png" @click="toggleInputType" class="eye" v-else>
               </div>
                <label class="pt-4">
                   <input type="checkbox" class="check-login">
                </label>
                <p class="remember">Remember Password</p>
                <button class="login-button" @click.prevent="onLogin()"><h5>Log in</h5></button>
                <button class="register-button" @click="$router.push('Register')"><h5>Register</h5></button>
             </form>
               <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
                   <div class="van-toast__text">success</div>
               </div>
             <div class="row pb-5">
                <div class="col-6 text-center" @click="$router.push('ForgotPassword')">
                   <svg data-v-33f88764="" class="svg-icon icon-clock_b forgetbg forgetbg text-danger" width="40px">
                      <use xlink:href="#icon-clock_b"></use>
                   </svg><br>
                   <p style="margin-top:-45px; font-size:12px;">Forgot Password</p>
                </div>
                <div class="col-6 text-center" @click="$router.push('CustomerService')">
                   <svg data-v-33f88764="" class="svg-icon icon-customer_b forgetbg forgetbg" width="40px">
                      <use xlink:href="#icon-customer_b"></use>
                   </svg><br>
                   <p style="margin-top:-45px; font-size:12px;">Customer Support</p>
                </div>
             </div>
          </div>
          <div class="col-md-4"></div>
       </div>
    </div>
 </template>
 <script>
   import axios from 'axios';
    export default {
        name: "EmailView",
        data() {
        return {
         inputType: 'password',
            user: {
                u_id: null,
                password: null
            }
        }

    },
    beforeCreate: function () {
        if (localStorage.getItem('username') != null)
            this.$router.push({ name: 'Home' });
    },

    methods: {
      toggleInputType() {
        this.inputType = this.inputType === 'password' ? 'text' : 'password';
        },
        pop(message) {
            document.getElementById("snackbar").innerHTML = message;
            document.getElementById("snackbar").style.display = "";
            setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 3000);
        },
        loading() {
            document.getElementById("loading").style.display = "";
            setTimeout(function () { document.getElementById("loading").style.display = "none"; }, 500);
        },
        onLogin() {
            if (this.user.u_id == null || this.user.password == null) {
                if (this.user.u_id == null) {
                    this.pop("UserId number is requied")
                } else {
                    this.pop("Password is requied")
                }

            }
            else {
                var data = new FormData();
                data.append("u_id", this.user.u_id);
                data.append("password", this.user.password);
                axios
                    .post(
                        "https://manager.tcgames.online/trova/src/api/user_api_tcgames.php?uid_login",
                        data
                    )
                    .then(res => {
                        if (res.data.error== true) {
                           //  this.loading();
                            localStorage.setItem('username', res.data.username);
                            console.log("Success", res.data.message);
                            this.pop(res.data.message);
                            setTimeout(() => { this.$router.push("Home"); }, 100);
                        } else {
                           //  this.loading();
                            console.log("Error", res.data);
                            this.pop(res.data.message);
                        }
                    })
                    .catch(err => {
                        console.log("Error", err);
                    });
            }
        },
        redirect() {
            console.log(this.$store.state.name);
            //this.$router.push({ name: 'Search' });
        },

    }
}
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>