<template>
   <div class="top-nav">
      <h5>
         <i class="fa fa-angle-left icon-back" @click="goBack"></i>
      </h5>
      <p class="customer-h5">Customer Service</p>
      <div class="pl-5 ml-5">
         <img src="../assets/png/en-4c6eba8e.png" class="lang" alt="">
         <span class="text-white lang1">EN</span>
      </div>
   </div>
   <div class="container-fluid" style="margin-top:50px;">
      <div class="row">
         <div class="col-md-4"></div>
         <div class="col-md-4">
            <img src="../assets/png/customerBg-d4a7e64a.png" class="suppport-img" style="margin-left: -15px;" width="449px" alt="">
            <a href="https://telegram.me/tcgamelotteryin" style="text-decoration: none; color: #758096;" target="_blank">
            <div class="support-call">
                <img src="../assets/png/CStype3-7588d980.png" width="30px" alt="">
                <p>SupportChat</p>
                <h5 ><i class="text-dark fa fa-angle-right icon-back"></i></h5>
            </div>
            </a>
         </div>
         <div class="col-md-4"></div>
      </div>
   </div>
</template>
<script>
   export default {
       name: "CustomerView",
       methods: {
         goBack() {
            window.history.back();
         }
       }
   }
   
</script>