<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div data-v-36cc3380="" class="Recharge__container">
          <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
             <div data-v-12a80a3e="" class="navbar-fixed">
                <div data-v-12a80a3e="" class="navbar__content">
                   <div data-v-12a80a3e="" class="navbar__content-left">
                      <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                         <!----><!----><!---->
                      </i>
                   </div>
                   <div data-v-12a80a3e="" class="navbar__content-center">
                      <!--v-if-->
                      <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">New Subordinate</div>
                   </div>
                   <div class="record__gamess">
                      <div :class="['today', { active: currentTab === 'tab1' }]" @click="setTab('tab1')">
                         Today
                      </div>
                      <div :class="['yesterday', { active: currentTab === 'tab2' }]" @click="setTab('tab2')">
                         Yesterday
                      </div>
                      <div :class="['week', { active: currentTab === 'tab3' }]" @click="setTab('tab3')">
                         This Week
                      </div>
                      <div :class="['month', { active: currentTab === 'tab4' }]" @click="setTab('tab4')">
                         This Month
                      </div>
                   </div>
                   <div v-if="currentTab === 'tab1'">
                    <div data-v-221aa0df="" class="subordinate__container-content__item ar-1px-b pt-3" v-for="rows in History" :key="rows.id">
                        <div data-v-221aa0df="">
                            <span data-v-221aa0df="" style="font-size: 13px;">{{ rows.mobile }}</span>
                            <span data-v-221aa0df="" style="font-size: 13px;">UID:{{ rows.usercode }}</span>
                        </div>
                        <div data-v-221aa0df="">
                            <span data-v-221aa0df="" style="font-size: 13px;">Level: {{ rows.level }}</span>
                            <span data-v-221aa0df="" style="font-size: 13px;">{{ rows.date }}</span>
                        </div>
                        </div>
                   </div>
                   <div v-if="currentTab === 'tab2'">
                        <div data-v-221aa0df="" class="subordinate__container-content__item ar-1px-b pt-3" v-for="rows2 in History2" :key="rows2.id">
                            <div data-v-221aa0df="">
                                <span data-v-221aa0df="" style="font-size: 13px;">{{ rows2.mobile }}</span>
                                <span data-v-221aa0df="" style="font-size: 13px;">UID:{{ rows2.usercode }}</span>
                            </div>
                            <div data-v-221aa0df="">
                                <span data-v-221aa0df="" style="font-size: 13px;">Level: {{ rows2.level }}</span>
                                <span data-v-221aa0df="" style="font-size: 13px;">{{ rows2.date }}</span>
                            </div>
                        </div>
                   </div>
                   <div v-if="currentTab === 'tab3'">
                        <div data-v-221aa0df="" class="subordinate__container-content__item ar-1px-b pt-3" v-for="rows3 in History3" :key="rows3.id">
                            <div data-v-221aa0df="">
                                <span data-v-221aa0df="" style="font-size: 13px;">{{ rows3.mobile }}</span>
                                <span data-v-221aa0df="" style="font-size: 13px;">UID:{{ rows3.usercode }}</span>
                            </div>
                            <div data-v-221aa0df="">
                                <span data-v-221aa0df="" style="font-size: 13px;">Level: {{ rows3.level }}</span>
                                <span data-v-221aa0df="" style="font-size: 13px;">{{ rows3.date }}</span>
                            </div>
                        </div>
                   </div>
                   <div v-if="currentTab === 'tab4'">
                        <div data-v-221aa0df="" class="subordinate__container-content__item ar-1px-b pt-3" v-for="rows4 in History3" :key="rows4.id">
                            <div data-v-221aa0df="">
                                <span data-v-221aa0df="" style="font-size: 13px;">{{ rows4.mobile }}</span>
                                <span data-v-221aa0df="" style="font-size: 13px;">UID:{{ rows4.usercode }}</span>
                            </div>
                            <div data-v-221aa0df="">
                                <span data-v-221aa0df="" style="font-size: 13px;">Level: {{ rows4.level }}</span>
                                <span data-v-221aa0df="" style="font-size: 13px;">{{ rows4.date }}</span>
                            </div>
                        </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>
 <script>
    import axios from 'axios';
     export default {
         name: 'NewSubordinateView',
         data(){
             return{
                currentTab: 'tab1',
                History: [],
                History2: [],
                History3: [],
                History4: [],
                userdetails: {
                   username: null,
                   total: 0,
                },
                rechargerecord: {
    
                },
                betbox: {
                   page: 1,
                   total:10,
                },
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
          goBack() {
             window.history.back();
          },
          getUserdetails(){
             this.userdetails.username = localStorage.getItem('username');
             axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=today_subordinate&username=' + this.userdetails.username).then((res) => {
                this.History = res.data;
                console.log(this.History);
                }).catch((err) => {
                    console.log(err);
                })
             axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=yesturday_subordinate&username=' + this.userdetails.username).then((res) => {
                this.History2 = res.data;
                console.log(this.History2);
                }).catch((err) => {
                    console.log(err);
                })
             axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=week_subordinate&username=' + this.userdetails.username).then((res) => {
                this.History3 = res.data;
                console.log(this.History3);
                }).catch((err) => {
                    console.log(err);
                })
             axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=month_subordinate&username=' + this.userdetails.username).then((res) => {
                this.History4 = res.data;
                console.log(this.History4);
                }).catch((err) => {
                    console.log(err);
                })
             axios.get('https://manager.tcgames.online/trova/src/api/game_statics.php?action=yesterday&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet1 = this.Users[0].total_bet1;
                this.userdetails.total_num1 = this.Users[2].total_num1;
                this.userdetails.total_win1 = this.Users[1].total_win1;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manager.tcgames.online/trova/src/api/game_statics.php?action=week&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet2 = this.Users[0].total_bet2;
                this.userdetails.total_num2 = this.Users[2].total_num2;
                this.userdetails.total_win2 = this.Users[1].total_win2;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manager.tcgames.online/trova/src/api/game_statics.php?action=month&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet3 = this.Users[0].total_bet3;
                this.userdetails.total_num3 = this.Users[2].total_num3;
                this.userdetails.total_win3 = this.Users[1].total_win3;
             }).catch((err) => {
                 console.log(err);
             })
          },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>