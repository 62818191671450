<template>
   <div class="container teamReport">
        <div class="row header">
            <i @click="goBack" class="fa fa-angle-left back-button"></i>
            <p>My Transactions</p>
        </div>
        <div class="row">
            <div class="date-tier">
                <div class="tier">
                    <select class="form-control">
                        <option value="">All</option>
                    </select>
                </div>
                <div class="tier">
                    <input type="date" />
                </div>
            </div>
        </div>
        <br>
        <div data-v-2565e76d="" class="transRecord__container-content">
            <!-- <div data-v-2565e76d="" class="transRecord__container-content__item">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Signup Bonus</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Signup Bonus</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">{{ userdetails.created_at }}</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹50.00</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div> -->
            <div data-v-2565e76d="" class="transRecord__container-content__item" v-for="rows in selfdepositbonus" :key="rows.id">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Self Deposit Bonus</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Self Deposit Bonus</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">{{ rows.created_at }}</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹{{ rows.amount }}</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <!-- <div data-v-2565e76d="" class="transRecord__container-content__item" v-for="row in lossbet" :key="row.id">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Bet</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Bet</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">{{ row.time }}</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right red" style="">₹{{ Number(row.amount).toFixed(2) }}</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div> -->
            <div data-v-2565e76d="" class="transRecord__container-content__item" v-for="deposit in depositbonus" :key="deposit.id">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Deposit Bonus</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Deposit Bonus</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">{{ deposit.created_at }}</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹{{ deposit.amount }}</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <div data-v-2565e76d="" class="transRecord__container-content__item" v-for="selery in DailySelery" :key="selery.id">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Daily Selery</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Daily Selery</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">{{ selery.date }}</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹{{ selery.income }}</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <!-- <div data-v-2565e76d="" class="transRecord__container-content__item">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Bet</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Bet</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">2024-08-02 21:53:16</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right red" style="">₹10.00</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <div data-v-2565e76d="" class="transRecord__container-content__item">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Win</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Win</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">2024-08-02 21:44:57</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹19.60</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <div data-v-2565e76d="" class="transRecord__container-content__item">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Bet</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Bet</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">2024-08-02 21:44:09</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right red" style="">₹10.00</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <div data-v-2565e76d="" class="transRecord__container-content__item">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Agent commission</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Agent commission</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">2024-08-02 01:01:03</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹2.83</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div>
            <div data-v-2565e76d="" class="transRecord__container-content__item">
               <div data-v-2565e76d="" class="transRecord__container-content__card">
                  <div data-v-2565e76d="" class="transRecord__container-content__card-top">
                     <h3 data-v-2565e76d="">Agent commission</h3>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-mid">
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Detail</div>
                        <div data-v-2565e76d="" class="right">Agent commission</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Time</div>
                        <div data-v-2565e76d="" class="right">2024-08-01 01:01:02</div>
                     </div>
                     <div data-v-2565e76d="" class="line">
                        <div data-v-2565e76d="" class="left">Balance</div>
                        <div data-v-2565e76d="" class="right green" style="">₹1.31</div>
                     </div>
                  </div>
                  <div data-v-2565e76d="" class="transRecord__container-content__card-bot"><textarea data-v-2565e76d="" class="textarea" name="remark" cols="30" rows="10" readonly=""></textarea></div>
               </div>
            </div> -->
         </div>
   </div>
</template>
 <script>
    import axios from 'axios';
     export default {
         name: 'RechargeHistoryView',
         data(){
             return{
                currentTab: 'tab1',
                userdetails: {
                   username: null,
                   total: 0,
                },
                selfdepositbonus: [],
                lossbet: [],
                depositbonus: [],
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
          goBack() {
             window.history.back();
          },
          getUserdetails(){
            this.userdetails.username = localStorage.getItem('username');
            axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=self_deposit&user=' + this.userdetails.username)
              .then(res => {
                  this.selfdepositbonus = res.data;
                  console.log(this.selfdepositbonus);
              })
              .catch(err => {
                  console.log(err);
              });
            axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=daily_selery&user=' + this.userdetails.username)
              .then(res => {
                  this.DailySelery = res.data;
                  console.log(this.DailySelery);
              })
              .catch(err => {
                  console.log(err);
              });
            axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=bet&user=' + this.userdetails.username)
              .then(res => {
                  this.lossbet = res.data;
                  console.log(this.lossbet);
              })
              .catch(err => {
                  console.log(err);
              });
            axios.get('https://manager.tcgames.online/trova/src/api/transactionApi.php?action=deposit&user=' + this.userdetails.username)
              .then(res => {
                  this.depositbonus = res.data;
                  console.log(this.depositbonus);
              })
              .catch(err => {
                  console.log(err);
              });
             axios.get('https://manager.tcgames.online/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.created_at=this.Users[0].created_at;
               
           }).catch((err) => {
               console.log(err);
           })
          },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>
 <style>
 [type="date"] {
   background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
 }
 [type="date"]::-webkit-inner-spin-button {
   display: none;
 }
 [type="date"]::-webkit-calendar-picker-indicator {
   opacity: 0;
 }
 </style>