<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
   <div data-v-36cc3380="" class="Recharge__container">
      <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
         <div data-v-12a80a3e="" class="navbar-fixed">
            <div data-v-12a80a3e="" class="navbar__content">
               <div data-v-12a80a3e="" class="navbar__content-left">
                  <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                     <!----><!----><!---->
                  </i>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-center">
                  
                  <div data-v-12a80a3e="" class="navbar__content-title nav-recharge">Fund Transfer</div>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-right">
                  <div data-v-36cc3380="" class="title nav-ttt-recharge" @click="$router.push('TransferHistory')">Transfer history</div>
               </div>
            </div>
         </div>
      </div>
      <!-- 余额组件 -->
      <div data-v-98c90f53="" data-v-36cc3380="" class="balanceAssets">
         <div data-v-98c90f53="" class="balanceAssets__header">
            <div data-v-98c90f53="" class="balanceAssets__header__left"><img data-v-98c90f53="" src="../assets/png/balance-e39ce400.png"> Balance</div>
         </div>
         <div data-v-98c90f53="" class="balanceAssets__main">
            <p data-v-98c90f53="">₹{{ Number(userdetails.balance).toFixed(2) }}</p>
            <img data-v-98c90f53="" src="../assets/png/refresh-8e0efe26.png" alt="">
         </div>
         <!-- <div class="balanceAssets__tip">
            <img :src="getIconsPublic('wallet/recharge', 'cip')" alt="" />
            </div> -->
      </div>
      <div data-v-9e03166f="" class="Recharge__content">
         <div data-v-9e03166f="" class="Recharge__content-paymoney boxStyle">
            <div data-v-9e03166f="" class="Recharge__content-paymoney__title">
               <svg data-v-9e03166f="" class="svg-icon icon-saveWallet">
                  <use xlink:href="#icon-saveWallet"></use>
               </svg>
               <p data-v-9e03166f="">Transfer amount</p>
            </div>
            <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list">
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(1000)">
                     <span data-v-9e03166f="">₹</span> 1k
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(2000)">
                     <span data-v-9e03166f="">₹</span> 2k
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(5000)">
                     <span data-v-9e03166f="">₹</span> 5k
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(10000)">
                     <span data-v-9e03166f="">₹</span> 10K
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(20000)">
                     <span data-v-9e03166f="">₹</span> 20K
                  </div>
                  
               </div>
               <div data-v-9e03166f="" class="Recharge__content-paymoney__money-list__item active">
                  <div data-v-9e03166f="" class="amount" @click="updateInput(50000)">
                     <span data-v-9e03166f="">₹</span> 50K
                  </div>
                  
               </div>
            </div>
            
            <div data-v-9e03166f="" class="Recharge__content-paymoney__money-input">
               <div data-v-9e03166f="" class="place-div">₹</div>
               <div data-v-9e03166f="" class="van-cell van-field amount-input" modelmodifiers="[object Object]">
                  <!----><!---->
                  <div class="van-cell__value van-field__value">
                     <div class="van-field__body">
                        <input type="tel" inputmode="numeric" required v-model="inputValue" id="amount" class="van-field__control" style="border:none; outline: none; background-color: transparent;" placeholder="Please enter the amount" /><!----><!----><!---->
                     </div>
                     <!----><!---->
                  </div>
                  <!----><!---->
               </div>
               
               <div data-v-9e03166f="" class="place-right"><img data-v-9e03166f="" src="/assets/png/clean-82487515.png" alt=""></div>
               
            </div>
            
            <div v-if="inputValue!==''">
               <div data-v-9e03166f="" class="Recharge__container-rechageBtn rechage_active" @click="proceed">Transfer</div>
            </div>
            <div v-else>
               <div data-v-9e03166f="" class="Recharge__container-rechageBtn" >Transfer</div>
            </div>
         </div>
      </div>
      <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
         <div class="van-toast__text">Minimum Recharge 1000</div>
      </div>
   </div>
</div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'FundTransferView',
        data() {
        return {
          inputValue: '',
          userdetails: {
               username: null,
               balance: null,
               page: 1,
               total22: 0
           },
           rechargerecord: {

            },
        };
      },
      beforeCreate: function () {
        if (localStorage.getItem('username') == null)
            this.$router.push('Login');
      },
      created: function () {
       this.getUserdetails();
      },
      methods: {
        updateInput(value) {
          this.inputValue = value;
        },
         goBack() {
            window.history.back();
         },
         pop(message) {
               document.getElementById("snackbar").innerHTML = message;
               document.getElementById("snackbar").style.display = "";
               setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1000);
            },
         getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.tcgames.online/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.balance = this.Users[0].balance;
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.tcgames.online/trova/src/api/bet.php?action=rechargerecord&user=' + this.userdetails.username + '&page1=' + this.userdetails.page).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
        },
         proceed() {
               this.username = localStorage.getItem('username');
               if(this.userdetails.balance >= this.inputValue)
               {
               if (document.getElementById("amount").value > 99 ) {
                  var link = "https://manager.tcgames.online/transfer.php?user="+this.username+"&am="
                //   var link = "https://powerindia.in/tcppay/index.php?uid="+this.username+"&amount="
                  var amount = document.getElementById("amount").value;
                  var payurl = link.concat(amount);
                  window.open(payurl, "_blank");
               }
               else {
                  var x = document.getElementById("snackbar");
                  x.style.display = "";
                  setTimeout(function e() {  x.style.display="none"; }, 1000);
               }
               }
               else
               {
                  this.pop("Fnsufficient Fund");
               }
            }
      }
    }
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>